export function createInjector<F extends () => unknown>(
  key: string,
  composable: F,
): {
  provide: () => ReturnType<F>;
  inject: () => ReturnType<F>;
} {
  const injectionKey: InjectionKey<ReturnType<typeof composable>> = Symbol(key);
  return {
    provide() {
      const state = composable();
      provide(injectionKey, state);
      return state;
    },
    inject() {
      const state = inject(injectionKey);
      if (!state) {
        throw new Error(`${key} is not provided`);
      }
      return state;
    },
  };
}
